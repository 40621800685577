import { useState, useCallback, useEffect, useRef, useContext } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import { LangContext } from "../state/LangContext";

import data from "../output_2024_002.json";

import Layout from "../components/Layout";
import Controllers from "../components/Controllers";
import Modal from "../components/Modal";

const containerStyle = {
  width: "100%",
  height: "97.5vh",
};

const mapOptions = {
  center: {
    lat: -24.0154479,
    lng: -46.2757662,
  },
  zoom: 14,
  disableDefaultUI: true,
};

const getStage = (stage) => {
  const stageLower = stage.toLowerCase();

  if (stageLower.includes("implementando")) {
    return {
      background: "var(--clr-purple)",
    };
  } else if (stageLower.includes("começando")) {
    return {
      background: "var(--clr-primary-30)",
    };
  } else if (stageLower.includes("planejando")) {
    return {
      background: "var(--clr-accent-50)",
    };
  } else if (stageLower.includes("inativo")) {
    return {
      background: "var(--clr-green)",
    };
  } else {
    return {
      background: "var(--clr-red)",
    };
  }
};

export default function Map() {
  const [search, setSearch] = useState("");
  const [types, setTypes] = useState([]);
  const [stages, setStages] = useState([]);
  const [modal, setModal] = useState(false);

  const [lang] = useContext(LangContext);

  const [currentData, setData] = useState(data.dataCords);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleType = (values) => {
    let update = types;
    for (let t of values) {
      let type = t.toLowerCase();
      if (update.includes(type)) {
        update = update.filter((t) => t !== type);
      } else {
        update = [...update, type.toLowerCase()];
      }
    }
    setTypes([...update]);
  };

  const handleStage = (stage) => {
    if (stages.includes(stage)) {
      setStages(stages.filter((t) => t !== stage));
    } else {
      setStages([...stages, stage.toLowerCase()]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const googleBillingPopupBtn = document.querySelector(".dismissButton");
      const googleBillingModal =
        googleBillingPopupBtn?.parentNode?.parentNode?.parentNode?.parentNode;
      if (googleBillingPopupBtn) googleBillingPopupBtn.remove();
      if (googleBillingModal) googleBillingModal.remove();
    }, 750);
  }, []);

  useEffect(() => {
    setTypes([]);
  }, [lang]);

  useEffect(() => {
    let update = data.dataCords;
    update = update.filter((row) =>
      Object.values(row).find((val) =>
        val.toString().toLowerCase().includes(search.toLowerCase())
      )
    );

    if (types.length) {
      update = update.filter((row) =>
        types.includes(row["dx_010"].toLowerCase())
      );
    }

    if (stages.length) {
      if (stages.includes("não iniciado")) {
        update = update.filter((row) => {
          const blackList = [
            "implementando",
            "começando",
            "planejando",
            "inativo",
          ];
          const exists = blackList.some((s) =>
            row["dx_003"]
              .toLowerCase()
              .includes(s)
          );

          return exists ? false : true;
        });
      } else {
        update = update.filter((row) => {
          const exists = stages.some((s) =>
            row["dx_003"]
              .toLowerCase()
              .includes(s)
          );

          return exists ? true : false;
        });
      }
    }

    setData(update);
  }, [search, types, stages]);

  const handleClick = (cords) => {
    const info = currentData.find(
      (row) => JSON.stringify(row.cords) === JSON.stringify(cords)
    );

    setModal(info);
  };

  return (
    <Layout>
      <Controllers
        stages={stages}
        types={types}
        search={search}
        handleStage={handleStage}
        handleType={handleType}
        handleSearch={handleSearch}
      />
      <InteractiveMap data={currentData} handleClick={handleClick} />
      <Modal data={modal} setModal={setModal} getStage={getStage} />
    </Layout>
  );
}

const InteractiveMap = ({ data, handleClick }) => {
  const { isLoaded } = useJsApiLoader({
    id: "MONITORAMENTO_MARIA_2023",
    googleMapsApiKey: "AIzaSyDjA2rgs1BYgSB7SbyYcjgHad642-Y0r40",
  });

  const [map, setMap] = useState(null);
  const prevMarkersRef = useRef([]);

  // Toggle show / hide markers
  useEffect(() => {
    for (let m of prevMarkersRef.current) {
      if (data.some((row) => JSON.stringify(row.cords) === m.id)) {
        m.marker.setMap(map);
      } else {
        m.marker.setMap(null);
      }
    }
  }, [data]);

  function createMarker(googleLib, map, row, _) {
    const { AdvancedMarkerElement, PinElement } = googleLib;
    const stageDetails = getStage(
      row["dx_003"]
    );

    const pin = new PinElement({
      scale: 1,
      glyphColor: "white",
      background: stageDetails.background,
      borderColor: stageDetails.background,
    });

    const marker = new AdvancedMarkerElement({
      map,
      position: row.cords,
      title: row["dx_015"],
      content: pin.element,
    });

    marker.addListener("click", () => handleClick(row.cords));

    return marker;
  }

  const onLoad = useCallback(async function callback(map) {
    const googleLib = await window.google.maps.importLibrary("marker");

    for (let i = 0; i < data.length; i++) {
      const row = data[i];

      const marker = createMarker(googleLib, map, row, i);

      prevMarkersRef.current.push({
        id: JSON.stringify(row.cords),
        marker: marker,
      });
    }

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      options={{
        mapId: "b4c8b212cc1e3f97",
        disableDefaultUI: true,
      }}
      mapContainerStyle={containerStyle}
      center={mapOptions.center}
      zoom={mapOptions.zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    />
  ) : (
    <></>
  );
};
